<template>
    <b-dropdown
        v-model="_value"
        append-to-body
        :mobile-modal="false"
        aria-role="menu"
        class="custom-dropdown"
        position="is-bottom-left"
        @active-change="isActive = $event">
        <template #trigger>
            <div :class="['is-align-items-center is-pointer', { 'is-pointer-none': disabled }]">
                <span
                    style="width: 30px"
                    :class="[`button is-rounded is-small is-align-items-center ${ selectedElement.type }`]">
                    <b-icon
                        :icon="selectedElement.icon"
                        :pack="selectedElement.pack || 'uil'"
                        size="is-small">
                    </b-icon>
                </span>
                <span class="ml-2 nowrap">
                    {{ selectedElement.text }}
                </span>
                <b-icon
                    v-if="!disabled"
                    :icon="isActive ? 'chevron-up' : 'chevron-down'"
                    class="ml-1">
                </b-icon>
            </div>
        </template>

        <b-dropdown-item
            :key="idx"
            v-for="(item, idx) in list"
            :disabled="item.value === _value"
            :value="item.value">
            <div class="is-align-items-center">
                <span
                    style="width: 30px"
                    :class="[`button is-rounded is-small is-align-items-center ${ item.type }`, { 'is-not-allowed': item.value === _value }]">
                    <b-icon
                        :icon="item.icon"
                        :pack="item.pack || 'uil'"
                        size="is-small">
                    </b-icon>
                </span>
                <span class="ml-2 nowrap">
                    {{ item.text }}
                </span>
            </div>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script lang="ts">
import { defineEmits, defineProps } from "@vue/runtime-dom";
import { computed, ref } from "@vue/composition-api";
import { TranslateResult } from "vue-i18n";
interface listItem {
  value: string;
  type: string;
  icon: string;
  text: TranslateResult;
  pack?: string;
}
interface Props {
  value: string | null;
  list: listItem[];
  isLoading?: boolean;
  disabled?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: true,
    type: [String, null]
  },
  list: {
    key: "list",
    required: true,
    type: Array
  },
  isLoading: {
    key: "isLoading",
    required: false,
    type: Boolean
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const _value = computed({
    get: () => props.value ?? props.list.find(item => item.value === props.value),
    set: value => emit("input", value)
  });
  const isActive = ref(false);
  const selectedElement = computed(() => props.list.find(item => item.value === props.value));
  return {
    _value,
    isActive,
    selectedElement
  };
};
export default __sfc_main;
</script>

<style>
    .custom-dropdown {
        .dropdown-item {
            &:not(:last-child) {
                border-bottom: 1px solid #E9E9E9;
            }

            &.is-active {
                background-color: white;
                color: black;
                font-weight: 600;
            }
            &.is-disabled {
                opacity: 1 !important;
            }
        }
        .is-pointer {
            cursor: pointer;
        }
        .is-not-allowed {
            cursor: not-allowed;
        }
        .dropdown-trigger:has(.is-pointer-none) {
            pointer-events: none;
        }
    }
</style>
