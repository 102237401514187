
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        









































































































































































$color-primary: #fbf1df;

::v-deep {
    .modal-card {
        width: 100%;
        max-width: 800px;

        &-head {
            font-size: .85rem;
            padding-bottom: 20px;
        }

        &-foot {
            padding: 0;
        }
    }
}
