
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






























































































































































































































































































































::v-deep {
    .has-background-transparent {
        background-color: transparent;
    }

    .table {
        th {
            &.is-sortable:hover {
                border-color: transparent;
            }
        }
    }
}
