
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




























































































































































































































































































































































































$size-point: 8px;

.item-point {
    position: absolute;
    top: -1px;
    left: 23px;
    width: $size-point;
    height: $size-point;
    border-radius: 50%;
    //noinspection SassScssResolvedByNameOnly
    background-color: $danger !important;
}
